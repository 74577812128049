import { memo } from 'react';

import 'react-phone-input-2/lib/style.css';
// import { useSearchParams } from 'next/navigation';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import { z } from 'zod';

import { messages } from '@/features/auth/localization';
import { registerPhoneNumber } from '@/features/auth/schema';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl, FormField, FormItem, FormLabel, FormMessage,
} from '@/components/ui/form';
// import { Input } from '@/components/ui/input';

interface Props {
  action: (values: z.infer<any>) => void,
  disabled: boolean,
}

const FormPhoneNumber = ({ action, disabled }:Props) => {
  const form = useForm<z.infer<any>>({
    resolver: zodResolver(registerPhoneNumber),
    defaultValues: {
      phone: '', ageVerification: false,
    },
    mode: 'onChange',
  });

  // const searchParams = useSearchParams();s
  // const idAffiliate = searchParams.get('id_afiliado');

  return (
    <div className="flex flex-col zyy gap-2 w-full">
      <Form
        watch={form.watch}
        getValues={form.getValues}
        getFieldState={form.getFieldState}
        setError={form.setError}
        clearErrors={form.clearErrors}
        setValue={form.setValue}
        trigger={form.trigger}
        formState={form.formState}
        resetField={form.resetField}
        reset={form.reset}
        handleSubmit={form.handleSubmit}
        unregister={form.unregister}
        control={form.control}
        register={form.register}
        setFocus={form.setFocus}
      >
        <form onSubmit={form.handleSubmit(action)} className="space-y-8 mx-[17px] mb-6">
          <div className="flex flex-col zyy gap-2 w-full">
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => {
                return (
                  <>
                    <FormItem className="w-full">
                      <FormControl className="w-full">
                        <PhoneInput
                          searchClass="!w-full"
                          buttonClass="input-phone-button"
                          dropdownClass="!bg-gray !text-primary_text !h-20 !rounded"
                          inputClass="!w-full ![border:none] ![outline:none] !bg-gray !self-stretch !h-10 !shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] !rounded-lg !flex !flex-row !justify-start !py-[15px] ! px-3.5 !box-border !font-text-sm-medium !font-medium !text-sm !text-slategray !min-w-[212px]"
                          country="hn"
                          onlyCountries={['hn', 'co']}
                          placeholder={messages.enterYourMobilePhone}
                          inputProps={{ required: true }}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                    {/* { idAffiliate !== null ? (
                      <>
                        <h1 className="">
                          {`${messages.affiliateCode}`}
                        </h1>
                        <FormField
                          control={form.control}
                          name="ID Afiliado"
                          render={() => {
                            return (
                              <FormItem>
                                <FormControl>
                                  <div className="relative">
                                    <Input
                                      type="text"
                                      placeholder={messages.idAffiliatemessage}
                                      value={idAffiliate ?? ''}
                                      name={field.name}
                                      className="input-form-text pr-10"
                                    />
                                  </div>
                                </FormControl>
                              </FormItem>
                            );
                          }}
                        />
                      </>
                    ) : null} */}
                  </>
                );
              }}
            />
          </div>
          <FormField
            control={form.control}
            name="ageVerification"
            render={({ field }) => {
              return (
                <FormItem className="w-full flex flex-row items-center justify-center">
                  <FormControl className="mr-4">
                    <Checkbox
                      onCheckedChange={field.onChange}
                      className="!bg-sivarbet-primary scale-150"
                      onChange={field.onChange}
                      value={field.value}
                      name={field.name}
                      ref={field.ref}
                      onBlur={field.onBlur}
                    />
                  </FormControl>
                  <FormLabel>{messages.messagesRegister}</FormLabel>
                </FormItem>
              );
            }}
          />
          <Button disabled={disabled} type="submit" className="button-primary-full hover:bg-sivarbet-primary-hover marketing-button">
            <div className="relative text-sm leading-[10px] font-medium font-text-sm-medium text-sivarbet-background text-center inline-block min-w-[35px]">
              {messages.validatePhoneNumber}
            </div>
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default memo(FormPhoneNumber);
